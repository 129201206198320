import axios from "axios";

import { createCookieInHour, getCookie } from "../utils/cookie";
import { leadActionsArray } from "../utils/leadActions";

export const headers = {
  "Content-Type": "application/json",
};

const LeadService = (payload) => {
  headers.auth_token = process.env.NEXT_PUBLIC_TOKEN;

  const Allowed_State = [ "Delhi", "Uttar Pradesh", "Others"];

  if(payload?.state && !Allowed_State.includes(payload?.state)){
    payload.state = "Others";
  }
  let paraLocal = JSON.parse(localStorage.getItem("urlParams"));
  let user_unique_id = JSON.parse(localStorage.getItem("user_id"));

  let appInfo = JSON.parse(localStorage.getItem("appInfo"));

  // let obj = getCookie("user_verified")
  //   ? JSON.parse(getCookie("user_verified"))
  //   : undefined;
  let cookie_payload = {
    name: payload?.name ?? "",
    phone: payload?.phone,
    verified: payload?.verified ? payload.verified : false,
    state:
      payload?.state === "Others" || payload?.state === undefined || payload?.state === "none" || !payload?.state
        ? "Unknown"
        : payload.state,
  };

  const leadActions = leadActionsArray();

  let vehicleClikedUser = getCookie("lead_trigger")
    ? JSON.parse(getCookie("lead_trigger"))
    : "";

  let experimentInfo = "NoOTPFAQ";
  // JSON.parse(localStorage.getItem("abExperimentInfo"))?.experimentVariant ??
  // "control";
  localStorage.setItem("phoneInfo", payload?.phone);
  createCookieInHour("user_verified", cookie_payload, 10);
  let userDetails = {
    name: payload?.name ?? "",
    phone: "+91" + payload?.phone,
    pan: payload?.pan ?? "",
    pincode: payload?.pincode ?? "",
    createdBy: "turno-offer", // TODO
    dob: payload?.dob ?? "",
    leadState: "New Lead", // TODO
    leadStatus: "Yet to Connect", // TODO
    language: "en",
    leadActions: leadActions,
    autoAssign: payload?.autoAssign ?? true,
    sendStoreLocation: payload?.sendStoreLocation,
    geoState:
      `${
        payload?.state === "Others" || payload?.state === undefined || payload?.state === "none" || !payload?.state
          ? paraLocal?.entire_url?.toLowerCase()?.includes("_ts")
            ? "Telangana"
            : "Unknown"
          : payload.state
      }` ?? "",
    metaDataList: [
      {
        key: "OPTED_EXCHANGE",
        value: payload?.optedExchange ?? "no",
      },
      {
        key: "UTM_SOURCE",
        value: paraLocal?.utm_source ?? "Organic",
      },
      {
        key: "UTM_CAMPAIGN",
        value: paraLocal?.utm_campaign ?? "999",
      },
      {
        key: "UTM_MEDIUM",
        value: paraLocal?.utm_medium ?? "Organic",
      },
      {
        key: "UTM_CONTENT",
        value: paraLocal?.utm_content ?? "Organic",
      },
      {
        key: "OFFER_SUB_SOURCE",
        value: payload?.subSource ?? "",
      },
      {
        key: "VEHICLE_SHORTLISTED",
        value: payload.vehicleSelected ?? "",
      },
      {
        key: "SOURCE_USER_ID",
        value: user_unique_id,
      },
      {
        key: "SOURCE_NAME",
        value: "turno-offer",
      },
      {
        key: "UTM_TERM",
        value: paraLocal?.utm_term ?? "Unknown",
      },
      {
        key: "UTM_ENTIRE_URL",
        value: paraLocal?.entire_url ?? "",
      },
      {
        key: "WEBSITE_LANGUAGE",
        value: payload?.language ?? "en",
      },
      {
        key: "LEAD_INTENT",
        value: payload?.leadIntent ?? "LOW_INTENT",
      },
      {
        key: "LEAD_FORM_SOURCE",
        value: vehicleClikedUser ?? "",
      },
      {
        key: "REFERRER_WEBSITE",
        value: document?.referrer ?? "",
      },
      {
        key: "FAQ_MESSAGE",
        value: payload?.message ?? "",
      },
      {
        key: "APP_INFO",
        value: appInfo?.appSource ?? "",
      },
      {
        key: "AB_EXPERIMENT_VERSION",
        value: experimentInfo ?? "control",
      },
    ],
  };

  if (payload?.refNumber?.length > 0) {
    userDetails.metaDataList.push({
      key: "REFERRER_PHONE_NUMBER",
      value: payload?.refNumber ?? "",
    });
  }

  if(payload?.sendStoreLocation){
    userDetails.metaDataList.push({
      key: "SEND_STORE_LOCATION",
      value:"TRUE",
    });
  }
  return axios.post("/leads/website", userDetails);
};

export default LeadService;
