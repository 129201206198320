// export const mapStates = {
//   AP: "Andhra Pradesh",
//   AR: "Arunachal Pradesh",
//   AS: "Assam",
//   BR: "Bihar",
//   CG: "Chattisgarh",
//   DL: "Delhi",
//   GA: "Goa",
//   GJ: "Gujarat",
//   HR: "Haryana",
//   HP: "Himachal Pradesh",
//   JK: "Jammu and Kashmir",
//   JH: "Jharkhand",
//   KA: "Karnataka",
//   KL: "Kerala",
//   LD: "Lakshadweep Islands",
//   MP: "Madhya Pradesh",
//   MH: "Maharashtra",
//   MN: "Manipur",
//   ML: "Meghalaya",
//   MZ: "Mizoram",
//   NL: "Nagaland",
//   OD: "Odisha",
//   PY: "Pondicherry",
//   PB: "Punjab",
//   RJ: "Rajasthan",
//   SK: "Sikkim",
//   TN: "Tamil Nadu",
//   TS: "Telangana",
//   TR: "Tripura",
//   UP: "Uttar Pradesh",
//   UK: "Uttarakhand",
//   WB: "West Bengal",
// };
export const mapStates = {
  KA: "Karnataka",
  UP: "Uttar Pradesh",
  DL: "Delhi"
}

// Commenting this out as it is not needed in LSQ
